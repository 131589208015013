
.dashboard {
  &-container {
    height: calc(100vh - 225px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media only screen and (max-width: 959px) {
    &-container {
      height: calc(100vh - 242px);
    }
  }
}

.category {
  &-container {
    height: calc(100vh - 225px);
    width: 100%;
  }

  &-image-unselected {
    width: 190px;
    height: 65px;
    // padding: 0px 8px;
  }

  &-image-selected {
    width: 190px;
    height: 65px;
  }

  &-unread-dot {
    width: 12px;
    height: 12px;
    position: absolute;
    margin-top: 15px;
    margin-left: -25px;
    border-radius: 6px;
    background: #2DA0B3;
  }
}

#choices {
  padding-top: 40px;
  padding-left: 110px;

  @media only screen and (max-width: 959px) {
    padding-top: 0;
    padding-left: 15px;
  }

  @media only screen and (max-width: 410px) {
    padding-top: 0;
    padding-left: 5px;
  }
}

#choices-section {
  padding-top: 40px;
  padding-left: 110px;

  @media only screen and (max-width: 959px) {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard {
    &-container {
      height: calc(100vh - 75px);
    }
  }
}
